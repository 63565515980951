export const readListFromString = (list_ = "") =>
	(/\[.+]/).test(list_)
		? list_
			.slice(1, -1)
			.split(",")
			.map(value => value.trim())
		: [];

export const hasAnyInList = (list_ = "", keys = []) => {
	const list = readListFromString(list_);
	return keys.some(r => list.includes(r));
};

export const isInList = (list_ = "", key = "") =>
	readListFromString(list_).includes(key);
