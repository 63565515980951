import {patternMatching} from 'core-global';
import {dom} from 'core-utils';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';

class NotificationBarLanguageSwitchClass {
	// TODO: remove class when notification bar uses cq entryService
	constructor() {
		STCK_STORE.observeStateFromStore(SELECTORS.FILTERS.getFilterItemsRaw, this.update.bind(this));
		this._replaceScFiltersInNotificationBar();
	}

	/**
	 * getter for defaults
	 * @static
	 * @returns {object} defaults
	 */
	static get defaults() {
		return {
			'selectorNotificationBarLink': '.nm-md-nb-link'
		};
	}

	/**
	 * replaceScFiltersInNotificationBar
	 * @returns {void} void
	 */
	_replaceScFiltersInNotificationBar() {
		const notificationBarLinks = dom.getElementsArray(NotificationBarLanguageSwitchClass.defaults.selectorNotificationBarLink);

		notificationBarLinks.forEach(async link => {
			const dataLinkUrl = link.getAttribute('data-link') || '';
			const replacedUrl = await patternMatching.getReplacedUrl(dataLinkUrl, '');
			link.href = NotificationBarLanguageSwitchClass._getCleanedFilterUrl(decodeURIComponent(replacedUrl));
		});
	}

	/**
	 * _getCleanedFilterUrl get url with single "filter="
	 * @static
	 * @param {string} url url to replace
	 * @returns {string} cleaned url
	 * @private
	 */
	static _getCleanedFilterUrl(url = '') {
		const urlWithSingleFilterString = url.replace('filter=filter=', 'filter=');
		const urlWithSingleHtmlEnding = urlWithSingleFilterString.replace(/(\.html)(.*)(\.html)/, '$1$2');
		if (urlWithSingleHtmlEnding.endsWith('#filter=')) {
			return urlWithSingleHtmlEnding.replace('#filter=', '');
		}
		return urlWithSingleHtmlEnding;
	}

	/**
	 * observer update method
	 * @override
	 * @returns {void} void
	 */
	update() {
		this._replaceScFiltersInNotificationBar();
	}
}

const notificationBarLanguageSwitchController = new NotificationBarLanguageSwitchClass();

export {notificationBarLanguageSwitchController, NotificationBarLanguageSwitchClass};
