import {device, dom} from 'core-utils';
import {intersectionObserver, IntersectionObserverClass} from '../../stockcars-utils-bundle';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {FORMAT_UTILS} from '../../stockcars-utils/utils';
import {scPage} from '../../stockcars-utils-bundle';

export default class ScMainNavigationElement extends HTMLElement {
	constructor() {
		super();
		this._resultsTotal = STCK_STORE.state.totalCount;
		this.unsubscribeFromStoreState = null;
		/**
		 * this._VAGUE_FILTER_VALUE - Max Value for filter, if greater -> this._VAGUE_FILTER_VALUE +
		 * @type {number}
		 */
		this._VAGUE_FILTER_VALUE = SETUPS.get('stockcar.vague.value')
			? parseInt(SETUPS.get('stockcar.vague.value'), 10)
			: 5;
	}

	static get defaults() {
		return {
			'classNavItemList': '.sc-main-navigation-list',
			'sListSelector': '.sc-filter-options ul',
			'sFilterOptionSelector': '.sc-filter-options',
			'scResultBtn': '.sc-main-navigation-result-button',
			'scResultBtnLink': '.sc-main-navigation-result-button-link',
			'scResultCount': '.j-sc-result-count',
			'scResultCountHits': '.j-sc-result-count-hits',
			'scNavigationInput': '#nm-navigation-toggle-btn',
			'scTitleItem': '.sc-navigation-item-link',
			'scTitleFilters': '.sc-navigation-mobile-filters',
			'scTitleFiltersNumber': '.sc-navigation-mobile-filters-number',
			'selectorMainNavigationScroll': '.sc-main-navigation-scroll-check',
			'classNavigationFixed': 'sc-nav-fixed',
			'sResultCount': '.sc-main-navigation-result-button .sc-main-navigation-result-count',
			'sResultLabel': '.sc-main-navigation-result-button .sc-main-navigation-result-label',
			'sResultTotal': '.sc-main-navigation-result-button .sc-main-navigation-result-total'
		};
	}

	/**
	 * update counter in headline
	 * @param {Number} count_ - number of total results
	 * @returns {void}
	 */
	_updateTotalCountsHeadline(count_) {
		const background = dom.getElement(ScMainNavigationElement.defaults.scResultBtn, this);
		if (count_ === 0) {
			background.classList.add('nm-j-inactive');
		}
		else {
			background.classList.remove('nm-j-inactive');
		}
		dom.getElement(ScMainNavigationElement.defaults.scResultCount, this).innerHTML = FORMAT_UTILS.formatCount(count_);
		dom.getElement(ScMainNavigationElement.defaults.scResultCountHits, this).innerHTML = (count_ === 1) ? window.i18n['sc.navig.hit'] : window.i18n['sc.navig.hits'];
	}

	/**
	 * isVagueFilterResults - checks if scope for vague filter results is set
	 * @return {boolean} - returns if scope is set
	 */
	_isVagueFilterResults() {
		const isVagueScoped = dom.getElement('.sc-scope-vague-filter-results');
		return dom.isElement(isVagueScoped);
	}

	/**
	 * render the DOM
	 * @method render
	 * @returns {void} returns nothing
	 */
	_render() {
		let resultCount = STCK_STORE.state.totalCount,
			resultButtonCount = dom.getElement(ScMainNavigationElement.defaults.sResultCount, this),
			resultButtonLabel = dom.getElement(ScMainNavigationElement.defaults.sResultLabel, this),
			resultButtonTotal = dom.getElement(ScMainNavigationElement.defaults.sResultTotal, this);

		if (resultCount > this._resultsTotal) {
			this._resultsTotal = resultCount;
		}

		if (resultButtonCount && resultButtonTotal) {
			resultButtonCount.innerHTML = this._generateResultCountLabel(resultCount);
			resultButtonTotal.innerHTML = this._generateResultCountLabel(this._resultsTotal);
		}

		if (resultButtonLabel) {
			resultButtonLabel.innerHTML = (this._generateResultCountLabel(resultCount) === '1') ? window.i18n['sc.navig.hit'] : window.i18n['sc.navig.hits'];
		}

		this._setResultButtonMinHeightForSafari(); // TODO: remove when Safari 10.x is deprecated
		this._updateTotalCountsHeadline(this._generateResultCountLabel(resultCount));
	}


	/**
	 * setResultButtonMinHeightForSafari
	 * @return {void} returns nothing
	 */
	_setResultButtonMinHeightForSafari() {
		// STCK-4821: Safari 10.x Bugfix (height needs to be set via JS, because nested Flexbox Childs can't inherit percentage height of flex parent - Webkit Bug / Fixed in Safari 11.x)
		// Please REMOVE next 4 lines and resultCount and navigation property when Safari 10.x isn't supported anymore

		const isSafari = (/^((?!chrome|android|crios|fxios).)*safari/i).test(navigator.userAgent);
		const navigation = dom.getElement(ScMainNavigationElement.defaults.classNavItemList, this);
		const resultButton = dom.getElement(ScMainNavigationElement.defaults.scResultBtnLink, this);

		if (!device.isiPhone() && isSafari && navigation && resultButton) {
			resultButton.style.minHeight = navigation.clientHeight + 'px';
			window.addEventListener('resize', dom.debounce(() => {
				resultButton.style.minHeight = navigation.clientHeight + 'px';
			}, 50));
		}
	}

	/**
	 * generateResultCountLabel
	 * @param {number} count count number for result label
	 * @return {string} resultLabel prepared result label
	 */
	_generateResultCountLabel(count) {
		return FORMAT_UTILS.formatCount(count, this._VAGUE_FILTER_VALUE, this._isVagueFilterResults());
	}

	/**
	 * [showPageFilters description]
	 * @param {Array} filters filters to be shown
	 * @param {HTMLElement} filterElem filterElement
	 * @return {void} returns nothing
	 */
	_showPageFilters(filters, filterElem) {
		if (!filters || !filters.length || filters.length === 0) {
			filterElem.innerHTML = '';
			return;
		}

		if (filters.length === 1) {
			filterElem.innerHTML = filters[0].label;
			return;
		}

		const filterText = filterElem.getAttribute('data-filtertext');
		filterElem.innerHTML = filters.length + ' ' + filterText;
	}

	/**
	 * inputChangeHandler handle change of navigation input
	 * @return {Void} returns nothing
	 */
	_inputChangeHandler() {
		const input = dom.getElement(ScMainNavigationElement.defaults.scNavigationInput, this);

		if (input && input.checked) {
			const mainNavigation = this;
			const filters = dom.getDataAttribute(mainNavigation, 'data-filter');
			const titles = document.querySelectorAll(ScMainNavigationElement.defaults.scTitleItem, this);

			for (let i = 0; i < titles.length; i++) {
				const pagePath = titles[i].getAttribute('data-href');
				const pathFilters = filters ? filters[pagePath] : [];
				const filterElem = titles[i].querySelector(ScMainNavigationElement.defaults.scTitleFilters);
				this._showPageFilters(pathFilters, filterElem);
			}
		}
	}

	/**
	 * add all Event Listener
	 * @method addEventListener
	 * @returns {void} returns nothing
	 */
	_addEventListener() {
		const domEventDelegate = dom.getEventDelegate('body');
		this._inputChangeHandler = this._inputChangeHandler.bind(this);
		domEventDelegate.on('change', ScMainNavigationElement.defaults.scNavigationInput, this._inputChangeHandler);
		/* navigation sticky scroll */
		// get closeset sc content
		const scContent = dom.closest(this, '.sc-content');
		this.scrollMarker = scContent.querySelector('.sc-main-navigation-scroll-check');
		if (dom.isElement(this.scrollMarker)) {
			intersectionObserver.registerObserver(this.scrollMarker, this._handleIntersectionForStickyNavigation.bind(this), {threshold: [0, 1]});
		}
		/* class switch for after body */
		window.addEventListener('resize', dom.debounce(this._unblockFooterOnMobile, 200));
	}

	/**
	 * _handleIntersectionForStickyNavigation - when scroll marker isnt visible, sticky nav should be shown
	 * @param {array} entries intersectionObserver entries
	 * @private
	 * @returns {void} void
	 */
	_handleIntersectionForStickyNavigation(entries) {
		if (!IntersectionObserverClass.isElementVisible(entries) && entries[0].rootBounds != undefined) { // eslint-disable-line eqeqeq
			// no sticky behaviour for mobile filter back button
			if (!scPage.isResultsPage() || window.innerWidth > 767) {
				this._setNavigationSticky();
			}
		}
		else {
			this._unsetNavigationSticky();
		}
	}

	/**
	 * _setNavigationSticky
	 * @private
	 * @returns {void} void
	 */
	_setNavigationSticky() {
		document.body.classList.add(ScMainNavigationElement.defaults.classNavigationFixed);
		// set navigation height to avoid content jumping
		const navHeight = this.clientHeight;
		this.parentNode.style.height = navHeight + 'px';
	}

	/**
	 * _unsetNavigationSticky
	 * @private
	 * @returns {void} void
	 */
	_unsetNavigationSticky() {
		document.body.classList.remove(ScMainNavigationElement.defaults.classNavigationFixed);
		// reset navigation height to auto
		this.parentNode.style.height = 'auto';
	}

	/**
	 * _unblockFooterOnMobile
	 * @returns {void} void
	 */
	_unblockFooterOnMobile(){
		const resultButton = document.querySelector('.sc-main-navigation-result-button');
		const body = document.querySelector('body');
		const positionResultBtn = window.getComputedStyle(resultButton).position;
		const footnotesFA = document.getElementById('audi-footer');
		if (footnotesFA){
			const emptyWrapper = document.querySelector('.nm-footer>.audi-footnotes-wrapper');
			if (emptyWrapper) {
				emptyWrapper.style.marginTop = 0;
			}
		}
		if (positionResultBtn === 'fixed'){
			body.classList.add('sc-sticky-results-button');
		}
		else {
			body.classList.remove('sc-sticky-results-button');
		}
	}

	/**
	 * remove all Event Listener
	 * @method addEventListener
	 * @returns {void} returns nothing
	 */
	_removeEventListener() {
		if (!!this.scrollMarker) { intersectionObserver.unregisterObserver(this.scrollMarker); }
		const domEventDelegate = dom.getEventDelegate('body');
		domEventDelegate.off('change', ScMainNavigationElement.defaults.scNavigationInput, this._inputChangeHandler);
	}

	connectedCallback() {
		this._addEventListener();
		this.update = this.update.bind(this);
		this.unsubscribeFromStoreState = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getTotalCountState, this.update);
		this.update();
	}

	disconnectedCallback() {
		this._removeEventListener();
		this.unsubscribeFromStoreState();
	}

	update() {
		this._render();
		this._unblockFooterOnMobile = this._unblockFooterOnMobile.bind(this);
		this._unblockFooterOnMobile();
	}
}

if (window.customElements.get('sc-main-navigation') === undefined) {
	window.customElements.define('sc-main-navigation', ScMainNavigationElement);
}
