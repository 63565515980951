function getFootnoteAnchor(power) {
	return `<a href="#nemo-fn_engine-power-boost-${power}" class="audi-j-footnote-reference" target="_self" data-reference-id="nemo-fn_engine-power-boost-${power}"><sup class="audi-footnote-anchor__text"></sup></a>`;
}

export const checkForEnginePowerBoostFootnote = ({vehicle = {}, techDataKey = "", techDataValue = ""} = {vehicle:{}, techDataKey:'', techDataValue:''}) => {
	// TODO hier muessten noch alle relevanten TechdataKeys eingetragen/ueberprueft werden, in denen das '220kw' vorkommen kann!!
	const MATCHING_KEYS = ['powerkw', 'powerDisplay', 'hybrid-electrical-power', 'max-output', 'max-output-kw'];
	const MATCHING_VALS = [220, 195];
	const MATCHING_MODELS = ['q4etron'];
	const MATCHING_UNIT = 'kw';
	const carline = vehicle.symbolicCarline && vehicle.symbolicCarline.code ? vehicle.symbolicCarline.code : '';

	const isRelevantCarline = MATCHING_MODELS.some(cl => cl.toLowerCase() === carline.toLowerCase());
	const isRelevantKey = MATCHING_KEYS.some(matchedKey => techDataKey.match(new RegExp(matchedKey, "gmi")));
	const matchedValues = MATCHING_VALS.filter(val => techDataValue.match(new RegExp(`${val}\\s?${MATCHING_UNIT}`, "gmi")));

	if (isRelevantKey && isRelevantCarline && matchedValues.length) {
		const power = matchedValues[0];
		const FOOTNOTE_HTML = getFootnoteAnchor(power);
		const match = new RegExp(`${matchedValues.pop()}\\s?${MATCHING_UNIT}`, "gmi").exec(techDataValue)[0];
		return techDataValue.split(match).join(`${match + FOOTNOTE_HTML}`);
	}
	return techDataValue;
};
