import {signal, router as ROUTER, appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';
import {fetch} from 'core-utils';
import {state as STATE} from 'core-global';
import {template as TPL_RENDERER} from 'core-utils';
import {searchLayerTemplate as SEARCH_TPL} from './search-layer-template';
import {searchAudicodeErrorTemplate as SEARCH_ERROR_TPL} from './search-audicode-error-template';

const SEARCH_OVERLAY =
		'.nm-header-overlay-container .nm-navigation-header-search-overlay.nm-header-overlay-is-open',
	ADDITIONAL_LOGO = '.nm-has-additional-logo',
	ADDITIONAL_LOGO_HEIGHT_ADJUSTMENT = 30,
	__ = {},
	exports = {
		__: __
	};

// private vars
__.mobileBreakpoint = 750;
__.searchLayerOpen = false;
__.searcListProposalsArr = null;
__.audicodeActivated = false;
__.oDefaults = {
	sAcceptAudicodeButton: '.nm-j-audicode-accept',
	sSearchInputSelector: '.nm-su .nm-su-input',
	sSearchSubmitSelector: '.nm-su .nm-su-submit',
	sSearchResetSelector: '.nm-su .nm-su-reset',
	sSearchFormSelector: '.nm-su form',
	sSearchContainer: '.nm-su-result-container'
};

/**
 * load search list proposals from SETUPS url
 * @returns {void}
 */
__.loadSearchListProposals = function () {
	const url = SETUPS.get('nemo.url.searchproposals');

	return fetch.getJson(url).then(
		function (response) {
			__.searcListProposalsArr =
				!!response && !!response.Proposals ? response.Proposals : [];
		},
		function (err) {
			console.error('search: error loading search list proposals.', err);
		}
	);
};

/**
 * search string in proposals
 * @param {string} searchString_ - the string to search
 * @returns {Array} - the search results
 */
__.searchResultListProposals = function (searchString_) {
	let searchString;
	let searchResults = [];

	if (!!searchString_) {
		searchString = searchString_.toLowerCase();

		if (!!__.searcListProposalsArr && __.searcListProposalsArr.length) {
			searchResults = __.searcListProposalsArr.filter((value) => (value.Search && value.Search.indexOf(searchString) > -1));
		}
	}
	return searchResults;
};

/**
 * add events
 * @returns {void}
 */
__.addEvents = function () { // eslint-disable-line max-statements
	const searchInput = document.querySelector(__.oDefaults.sSearchInputSelector);
	if (!searchInput) {
		return;
	}
	__.onOpenSearchLayer = DOM_UTILS.debounce(__.onOpenSearchLayer, 100);
	searchInput.addEventListener('keyup', __.onOpenSearchLayer);
	searchInput.addEventListener('paste', __.onOpenSearchLayerDelayed);
	searchInput.addEventListener('click', __.onOpenSearchLayer);

	const searchInputSubmit = document.querySelector(__.oDefaults.sSearchSubmitSelector);
	searchInputSubmit.addEventListener('click', __.onOpenSearchLayer);

	const searchInputReset = document.querySelector(__.oDefaults.sSearchResetSelector);
	searchInputReset.addEventListener('click', __.resetSearch);

	const searchInputFormSubmit = document.querySelector(__.oDefaults.sSearchFormSelector);
	searchInputFormSubmit.addEventListener('submit', __.formSubmit);

	__.eventBus.on(EVENTS.MENUFLYOUT_OPEN, __.clearSearch);
	__.eventBus.on(EVENTS.MENUFLYOUT_CLOSE, __.emptySearchResults);

	let eventType = 'click';
	// this is needed to make closing work on the iPad
	if (typeof document.documentElement.ontouchstart !== 'undefined') {
		eventType = 'touchstart';
	}
	document.body.addEventListener(eventType, __.onClickOutsideOfSearchLayer);
};

/**
 * remove HTML from search results container
 * @returns {void}
 */
__.emptySearchResults = function () {
	DOM_UTILS.getElement(__.oDefaults.sSearchContainer).innerHTML = '';
};

/**
 * reset the search
 * @returns {void}
 */
__.resetSearch = function () {
	const input = DOM_UTILS.getElement(SEARCH_OVERLAY + ' input.nm-su-input');
	input.focus();
	__.emptySearchResults();
	input.dispatchEvent(new KeyboardEvent('keyup', {'key': ' '}));
};

/**
 * @param {Object} data_ - data to clear
 * @returns {void}
 */
__.clearSearch = function (data_) {
	if (typeof data_.item !== 'undefined') {
		const input = DOM_UTILS.getElement(SEARCH_OVERLAY + ' input.nm-su-input');
		input.value = '';
		input.focus();
	}
};

/**
 * conditional action for submit on return (prevent url form submit in some cases)
 * @param {Event} event_ - the submit event
 * @returns {void}
 */
__.formSubmit = function (event_) {
	let searchString, gsaUrl;

	if (!!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}

	// audicode configuration was loaded
	if (__.loadedConfiguration) {
		__.acceptLoadedConfiguration();
		return false;
	}

	// audicode loading
	if (document.querySelector('.nm-j-audicode-loading')) {
		return false;
	}
	searchString = __.readSearchStringFromInput();

	if (searchString.length <= 0) {
		return false;
	}
	gsaUrl = __.buildGsaSearchUrl(searchString);

	if (!!gsaUrl) {
		__.performSearch(gsaUrl, searchString);
	}
	return false;
};

/**
 * read search string from input
 * @returns {string} - the extracted search string
 */
__.readSearchStringFromInput = function () {
	// get the user input
	let searchString = DOM_UTILS.getElement(__.oDefaults.sSearchInputSelector).value || '';
	// remove leading and trailing whitespaces
	searchString = searchString
		.replace(/^\s+/g, '')
		.replace(/</g, '')
		.replace(/>/g, '');
	return searchString;
};

/**
 * delay search layer for paste operation
 * @param {Event} ev paste Event
 * @returns {void}
 */
__.onOpenSearchLayerDelayed = function (ev) {
	setTimeout(() => {
		__.onOpenSearchLayer(ev);
	}, 50);
};

/**
 * fake event to catch default event
 * @returns {boolean} false
 */
__.preventEvent = function () {
	return false;
};


/**
 * detect audicode from input and store
 * @param {Object} resultList_ - the result list object
 * @param {string} searchString_ - the search string
 * @returns {Object} - audicode-enriched result list
 */
__.detectAndStoreAudiCode = function (resultList_, searchString_) {
	const searchInputFormSubmit = document.querySelector(__.oDefaults.sSearchFormSelector);
	if (
		searchString_.length === 8 &&
		searchString_.search(/[Aa]{1}[a-zA-Z0-9]{7}/) !== -1
	) {
		resultList_.audicode = searchString_.toUpperCase();
		// Deactivate submit.

		searchInputFormSubmit.removeEventListener('submit', __.formSubmit);
		searchInputFormSubmit.addEventListener('submit', __.preventEvent);
	}
	else {
		// Re-activate submit.
		searchInputFormSubmit.removeEventListener('submit', __.preventEvent);
		searchInputFormSubmit.addEventListener('submit', __.formSubmit);
	}
	return resultList_;
};

/**
 * handle the audicode input
 * @param {string} searchString_ - the search string
 * @param {*} resultList_ - the result list
 * @returns {Object} - the enriched result list
 */
__.handleAudiCodeInput = function (searchString_, resultList_) {
	const audicodeStopWords = SETUPS.get('nemo.search.audicodestopwords');

	// only check for Audicode if search did not return a match
	if (
		resultList_.audicode &&
		!resultList_.search.length &&
		(!!audicodeStopWords && audicodeStopWords.indexOf(searchString_) === -1)
	) {
		__.triggerAudicodeLoad(resultList_.audicode);
	}
	else {
		resultList_.audicode = null;
	}
	resultList_.searchString = !!searchString_
		? searchString_
		: __.readSearchStringFromInput();
	resultList_.siteMapSearch = exports.searchNavigation(searchString_, 'siteMap');
	resultList_.modelSearch = exports.searchNavigation(searchString_, 'model');
	resultList_.footerSearch = exports.searchNavigation(searchString_, 'footer');
	resultList_.gsaSearchUrl = __.buildGsaSearchUrl(searchString_);
	resultList_.searchListProposals = __.searchResultListProposals(
		searchString_
	);
	return resultList_;
};

/**
 * @returns {Array} items - site navigation items
 * @default []
 */
__.getSiteNavigationStorage = function () {
	const storage = SETUPS.get('siteNavigation');

	return !!storage ? storage : [];
};

/**
 * @returns {Array} items - footer navigation items
 * @default []
 */
__.getFooterNavigationStorage = function () {
	const storage = SETUPS.get('footerNavigation');

	return !!storage ? storage : [];
};

/**
 * @returns {Array} items - model navigation items
 * @default []
 */
__.getModelNavigationStorage = function () {
	// #api-003.7 - resolve dependency
	const storage = SETUPS.get('modelNavigation');

	return !!storage ? storage : [];
};

/**
 * trigger tracking actions and resolve updated search url
 * @param {string} gsaUrl_ - the new document.location to target
 * @param {string} searchString_ - the targetUrl
 * @returns {void}
 */
__.performSearch = function(gsaUrl_, searchString_) {
	const payload = __.generateTrackingPayload(gsaUrl_, searchString_);
	const searchSubmitEvent = new CustomEvent('CUSTOM_TRACKING_EVENT', payload);

	document.dispatchEvent(searchSubmitEvent);
	document.location = gsaUrl_;
};

/**
 * generates the payload object to enrich the customEvent
 * @param {string} gsaUrl_ - the new document.location to target
 * @param {string} searchString_ - the targetUrl
 * @returns {void}
 */
__.generateTrackingPayload = function(gsaUrl_, searchString_) {
	let elementName = 'button';

	if (document.activeElement.classList.contains('nm-su-input')) {
		elementName = 'other';
	}

	const payload = {
		detail: {
			eventInfo: {
				eventAction: 'internal_link',
				eventName: 'main search - submit'
			},
			attributes: {
				componentName: 'main search',
				label: '',
				currentURL: window.location.href,
				targetURL: gsaUrl_,
				clickID: '',
				elementName: elementName,
				value: searchString_,
				pos: ''
			}
		}
	};

	return payload;
};

/**
 * Search for Items (name,search,url) within the Navigation parts (Model-, Sitemap- Footernavigation)
 * @param {string} inputString_ - Input from search field
 * @param {string} navigationPart_ - where to search (model||siteMap||footer)
 * @returns {Array} {name search url}
 * @dafault []
 */
exports.searchNavigation = function (inputString_, navigationPart_) {
	let navigationItems = [];
	let whereArr = [];
	let searchString = inputString_.toLowerCase();

	switch (navigationPart_) {
		case 'model':
			whereArr = __.getModelNavigationStorage();
			break;
		case 'siteMap':
			whereArr = __.getSiteNavigationStorage();
			break;
		case 'footer':
			whereArr = __.getFooterNavigationStorage();
			break;
		default:
			whereArr = [];
	}

	whereArr.forEach(function (value) {
		if (!value.search) {
			return true;
		}
		if (value.search.indexOf(searchString) !== -1 && !!value.url) {
			navigationItems.push(value);
		}
	});
	return navigationItems;
};

/**
 * create and fill the result list
 * @param {String} searchString_ - the search string
 * @returns {Object} - the result list
 */
__.generateResultList = function (searchString_) {
	let resultList = {},
		searchString = searchString_,
		resultArray;

	__.searchLayerOpen = true;

	resultList = __.detectAndStoreAudiCode(resultList, searchString);

	if (searchString.indexOf('|') !== -1) {
		resultList.prstring = searchString;
	}

	if (searchString.search(/^[a-z0-9]*=[true|false]*$/) !== -1) {
		resultArray = (/^([a-z0-9]*)=([true|false]*)$/).exec(searchString);
		STATE.triggerModeChange(resultArray[1], resultArray[2]);
	}
	resultList.search = [];
	resultList = __.handleAudiCodeInput(searchString, resultList);

	return resultList;
};

/**
 * handle the search
 * @param {Event} event_ the original event
 * @param {string} currentInput_ - the current input
 * @param {string} searchString_ - the search string
 * @param {jQuery} $currentLayerContainer_ - the layer container
 * @returns {void}
 */
__.handleSearch = function (
	event_,
	currentInput_,
	searchString_,
	$currentLayerContainer_
) {
	const resultList = __.generateResultList(searchString_);
	let renderedTemplate;
	const changed = currentInput_ !== searchString_ || event_.type === 'click';

	// open search layer on click or change of text input
	if (searchString_.length > 1 && changed) {
		renderedTemplate = TPL_RENDERER.render(SEARCH_TPL, resultList);
		// trigger dirty repaint
		let element = $currentLayerContainer_;
		element.innerHTML = renderedTemplate;

		__.eventBus.emit(EVENTS.SEARCH_OPEN, resultList);
	}
	else if (searchString_.length <= 1) {
		// nur schließen, wenn wir ein zu kurzes suchwort haben, bei keiner änderung offen bleiben.
		__.closeSearchLayer();
	}
};

/**
 * handle submit in small viewport
 * @param {Event} event_ - the original click event
 * @param {string} searchString_ - the search string
 * @returns {void}
 */
__.handleSmallViewportSubmit = function (event_, searchString_) {
	let searchString = searchString_;
	let gsaUrl;

	if (
		window.outerWidth < __.mobileBreakpoint &&
		event_.target.classList.contains('nm-su-submit')
	) {
		// audicode loading
		if (document.querySelector('.nm-j-audicode-loading')) {
			if (__.audicodeActivated) {
				__.acceptLoadedConfiguration();
			}
		}
		else {
			searchString = __.readSearchStringFromInput();
			gsaUrl = __.buildGsaSearchUrl(searchString);

			if (gsaUrl !== null) {
				document.location = gsaUrl;
			}
		}
	}
};

/**
 * check whether exit condition for opening search layer applies
 * @param {Event} event_ - the original click event
 * @returns {boolean} true in case of exit condition, false else (and default)
 */
__.isExitConditionFulfilled = function (event_) {
	if (!__.audicodeActivated) {
		__.loadedConfiguration = null;
	}
	else {
		// search result is visible=> accept audicode on return
		if (
			event_.keyCode === 13 &&
			// is visible
			DOM_UTILS.getElement(__.oDefaults.sAcceptAudicodeButton).offsetHeight > 0
		) {
			__.acceptLoadedConfiguration();
			return true;
		}
	}
	// ignore shift and return (!)
	if (event_.keyCode === 16 || event_.keyCode === 13) {
		return true;
	}
	return false;
};

/**
 * handle for open search layer interaction
 * @param {Event} event_ - the interaction event
 * @returns {void}
 */
__.onOpenSearchLayer = function (event_) {
	let $currentLayerContainer, searchString, currentInput;
	const $container = event_.target.closest('.nm-su');
	if (__.isExitConditionFulfilled(event_) || !$container) {
		return;
	}
	// @TODO unused??
	// __.searchForm = jQuery(event_.target).parents('form');

	$currentLayerContainer = DOM_UTILS.getElement(__.oDefaults.sSearchContainer, $container);
	searchString = __.readSearchStringFromInput();
	currentInput = $container.getAttribute('data-current');

	// check if input changed
	if (currentInput !== searchString) {
		__.audicodeActivated = false;
		__.loadedConfiguration = null;
	}
	$container.setAttribute('data-current', searchString);

	__.handleSearch(event_, currentInput, searchString, $currentLayerContainer);
	__.setFlyoutHeight($currentLayerContainer);
	__.handleSmallViewportSubmit(event_, searchString);
};

/**
 * build the gsa search url
 * @param {string} searchString_ - the search string
 * @returns {void}
 */
__.buildGsaSearchUrl = function (searchString_) {
	let url = SETUPS.get('gsaSearchLink') || null;

	if (!url) {
		return null;
	}
	url += '#search=' + encodeURIComponent(searchString_.toLowerCase());
	return url;
};


/**
 * handler for click outside of search layer
 * @param {Event} event_ - the click event
 * @returns {void}
 */
__.onClickOutsideOfSearchLayer = function (event_) {
	// only close the layer if there are no other layers opened on top of it or
	// it is clicked inside of the search layer
	let clickedTarget = event_.target;

	if (
		__.searchLayerOpen &&
		__.isClickedTargetOutsideSearchLayer(clickedTarget) &&
		__.isClickedTargetOutsideSearchSearchInputField(clickedTarget) &&
		__.isClickedTargetOutsideAnyOtherLayer(clickedTarget)
	) {
		__.closeSearchLayer();
	}
};

/**
 * has the click been outside of the search layer?
 * @param {Element} clickedTarget_ the click target
 * @returns {boolean} - true if click target is outside of the search layer, else false
 */
__.isClickedTargetOutsideSearchLayer = function (clickedTarget_) {
	// test if element is child of a search layer
	return DOM_UTILS.isElementOutsideOfElementWithSelector(
		clickedTarget_,
		__.oDefaults.sSearchContainer
	);
};

/**
 * has the click been outside of the search input field?
 * @param {Element} clickedTarget_ - the click target
 * @returns {boolean} - true if click target outside of search input field, else false
 */
__.isClickedTargetOutsideSearchSearchInputField = function (clickedTarget_) {
	// test if element is child of a search layer
	return DOM_UTILS.isElementOutsideOfElementWithSelector(
		clickedTarget_,
		'div.nm-su'
	);
};

/**
 * has the click been outside of any other layer?
 * @param {Element} clickedTarget_ - the click target
 * @returns {boolean} - true if click target is outside of any other layer, else false
 */
__.isClickedTargetOutsideAnyOtherLayer = function (clickedTarget_) {
	// test if element is child of a any layer
	return DOM_UTILS.isElementOutsideOfElementWithSelector(
		clickedTarget_,
		'.nm-layer-wrapper'
	);
};

/**
 * close the search layer
 * @returns {void}
 */
__.closeSearchLayer = function () {
	/*
		 * Delay emptying of container, so other listeners on elements
		 * in the container can be executed (e.g. audicode button tracker).
		 */
	setTimeout(function () {
		__.searchLayerOpen = false;
		DOM_UTILS.getElement(__.oDefaults.sSearchContainer).innerHTML = '';
		__.resetSearch();
		__.eventBus.emit(EVENTS.SEARCH_CLOSE);
	}, 100);
};

__.getContextForDPUrequest = function () {
	let config = SETUPS.get("nemo.default.data") || {};
	config = config.header ? config.header.context : '';
	return config;
};

/**
 * trigger audicode loading
 * @param {string} audiCode_ - the audicode to load
 * @returns {void}
 */
__.triggerAudicodeLoad = function (audiCode_) {
	const audicodeServiceUrl = __.getDpuUrl();
	const url = audicodeServiceUrl + 'audicode';
	let audiCode = audiCode_;

	if (typeof audiCode !== 'undefined') {
		audiCode = audiCode.toUpperCase();
	}
	// return Promise
	const urlWithParams = `${url}?context=${__.getContextForDPUrequest()}&audicode=${audiCode}`;
	fetch.getJson(urlWithParams).then(__.checkDPUresponseStatus)
		.then(
			function (data) {
				__.onAudiCodeLoaded(data);
			},
			function (error) {
				error.message =
					error.message || window.i18n['standard-audicode-error'];
				__.showAudiCodeLoadError(error);
			}
		);
};

__.getDpuUrl = function () {
	return SETUPS.get('nemo.url.dpu') || null;
};

/**
 * show audicode loading error
 * @param {Object} error_ - the error object
 * @returns {void}
 */
__.showAudiCodeLoadError = function (error_) {
	const $resultContainer = DOM_UTILS.getElement(__.oDefaults.sSearchContainer);
	let renderedTemplate;

	error_.message = exports.getErrorMessage(error_);

	renderedTemplate = TPL_RENDERER.render(SEARCH_ERROR_TPL, error_);
	console.error(error_);
	// Re-activate form submission.
	const searchInputFormSubmit = document.querySelector(__.oDefaults.sSearchFormSelector);
	searchInputFormSubmit.removeEventListener('submit', __.preventEvent);
	searchInputFormSubmit.addEventListener('submit', __.formSubmit);


	$resultContainer.innerHTML = renderedTemplate;

	__.setFlyoutHeight($resultContainer);
};

/**
 * get translated ErrorMessage by its DPU ERROR_CODE
 * @param {Object|string} error - DPU-Error
 * @returns {string} translated error message
 */
exports.getErrorMessage = function (error) {
	const i18nErrorMessage = __.getErrorMessageFromI18N(error);
	const dpuErrorMessage = __.getErrorMessageFromDpuError(error);
	const errorMessage = i18nErrorMessage ? i18nErrorMessage : dpuErrorMessage;

	if (!errorMessage) {
		return __.getErrorMessageFromI18N('DEFAULT');
	}

	return errorMessage;
};

__.getErrorMessageFromI18N = function (error) {
	const errorCode = typeof error === 'string' ? error : error.id;
	return window.i18n['error.message.' + errorCode];
};

__.getErrorMessageFromDpuError = function (error) {
	return error.message ? error.message : null;
};

/**
 * handle an audicode error
 * @param {string} message_ - the error message
 * @returns {void}
 */
__.handleAudiCodeError = function (message_) {
	let error = {};

	if (!!message_) {
		error.message = message_;
	}
	__.audicodeActivated = false;
	__.loadedConfiguration = undefined; // forget loaded Configuration Data
	__.showAudiCodeLoadError(error);
};

/**
 * handler for audicode loaded return from DPU
 * @param {Object} data_ - the audicode data
 * @returns {void}
 */
__.onAudiCodeLoaded = function (data_) {
	let audicode, $el;

	__.loadedConfiguration = data_;

	// look for an input field with the same Audicode
	if (
		__.loadedConfiguration &&
		data_.audicode &&
		__.loadedConfiguration.audicode.id
	) {
		audicode = __.loadedConfiguration.audicode.id.toUpperCase();
		// search for an input field with the returne Audicode
		$el = DOM_UTILS.getElementsArray('.nm-su-input').filter((element) => element.value && element.value.toUpperCase() === audicode
		);
		if (!!$el && $el.length === 1) {
			__.audicodeActivated = true;
			__.showLoadedConfiguration($el[0]);
		}
		else {
			__.handleAudiCodeError();
		}
	}
	else {
		__.handleAudiCodeError(
			window.i18n['standard-audicode-error'],
			'ERROR loading Audicode'
		);
	}
};

/**
 * show the loaded Configuration
 * @param {Element} $input_ - the input element
 * @returns {void}
 */
__.showLoadedConfiguration = function ($input_) {
	const $container = $input_.closest('.nm-su'),
		$currentLayerContainer = DOM_UTILS.getElement(__.oDefaults.sSearchContainer, $container),
		configData = {
			search: {},
			audicode: __.loadedConfiguration.audicode.id,
			configuration: __.loadedConfiguration,
			mediaservice: SETUPS.get('nemo.url.mediaservice') || null,
			success: !!__.loadedConfiguration.audicode.success,
			'vtp-audicode': !!__.loadedConfiguration.audicode['other-url'],
			'other-url': !!__.loadedConfiguration.audicode['other-url']
				? __.loadedConfiguration.audicode['other-url']
				: null,
			message: __.loadedConfiguration.audicode.message
		},
		renderedTemplate = TPL_RENDERER.render(SEARCH_TPL, configData);
	$currentLayerContainer.innerHTML = renderedTemplate;
	const acceptBtn = DOM_UTILS.getElementsArray(__.oDefaults.sAcceptAudicodeButton, $container);
	if (acceptBtn.length) {
		acceptBtn.map((btn) => btn.addEventListener('click', __.acceptLoadedConfiguration));
	}
	__.setFlyoutHeight($currentLayerContainer);
};

/**
 * accept the loaded configuration
 * @param {Event|null} event_ - the click event
 * @returns {void}
 */
__.acceptLoadedConfiguration = function (event_) { // eslint-disable-line max-statements
	let audicode = !!(__.loadedConfiguration && __.loadedConfiguration.audicode)
		? __.loadedConfiguration.audicode
		: null;

	if (
		!!event_ &&
		!SETUPS.get('scope.nemo.audicode.externallink') &&
		typeof event_.preventDefault === 'function'
	) {
		event_.preventDefault();
	}

	if (!!event_ && SETUPS.get('scope.nemo.audicode.externallink')) {
		return true;
	}
	__.eventBus.emit(EVENTS.SEARCH_LOAD_AUDICODE, {
		audicode: audicode
	});
	__.closeSearchLayer();

	const carlineID2Url = SETUPS.get('nemo.carlineID2Url') || {};
	const carlineEntryUrl = carlineID2Url[__.loadedConfiguration.configuration.carline].baseurl;

	// always go to the defined url to start a configuration
	ROUTER.open('page', carlineEntryUrl);
	if (!!__.loadedConfiguration.audicode['other-url']) {
		if (SETUPS.get('scope.nemo.audicode.externallink')) {
			event_.target.href = __.loadedConfiguration.audicode['other-url'];
			return true;
		}
		else {
			window.location = __.loadedConfiguration.audicode['other-url'];
		}
	}

};

/**
 * set the flyout height
 * @param {Element} $overlay_ - the search overlay
 * @returns {void}
 */
__.setFlyoutHeight = function ($overlay_) {
	const el = DOM_UTILS.getElement('.nm-su');

	el.classList.remove('noSrcollbars');

	if ($overlay_.offsetHeight < 410) {
		el.classList.add('noSrcollbars');
	}

	let flyoutHeight = $overlay_.offsetHeight + 150;
	let doubleRow = document.querySelector(ADDITIONAL_LOGO);

	if (doubleRow) {
		flyoutHeight += ADDITIONAL_LOGO_HEIGHT_ADJUSTMENT;
	}
	// @TODO remove open class from default?
	DOM_UTILS.getElement(SEARCH_OVERLAY).style.height = flyoutHeight + "px";
};

/**
 * triggers initialization
 * @returns {void} - nothing
 */
__.initialize = function () {
	if (SETUPS.get('page.context') === "STCK") {
		__.eventBus = signal.getEmitter();
		__.addEvents();
		__.loadSearchListProposals();
	}
};
__.initialize();
export {exports as audiSearch};
