import {CoreEvents} from '@audi/audi-core-events';
import {dom} from 'core-utils';
import {globalEventEmitter} from '../../stockcars-bundle';
import {scPage} from '../../stockcars-utils-bundle';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';

class LanguageSwitchControllerClass {
	constructor() {
		this.globalEventBus = globalEventEmitter;
		this._bindContextToFunctions();
		this._addEvents();
		this._patchLanguageSwitchUrls();

	}

	/**
	 * default values used several times
	 * @returns {{selectorLanguageSwitch: string, country: string, language: string, market: string, target: string, id: string}} returns defaults
	 */
	static get defaults() {
		return {
			selectorLanguageSwitch: '.nm-j-language-switch',
			country: 'country',
			language: 'language',
			market: 'sc_market',
			target: 'target',
			id: 'id',
			filter: 'filter'
		};
	}

	/**
	 * _getCleanedUrl - clean url and return original url
	 * @param {string} url_ url
	 * @returns {string} returns original url with only country and language
	 * @private
	 */
	_getCleanedUrl(url_ = '') {
		const url = url_.split('?')[0] || '';
		const country = scPage.getUrlParameterByName(
			LanguageSwitchControllerClass.defaults.country,
			url_
		);
		const language = scPage.getUrlParameterByName(
			LanguageSwitchControllerClass.defaults.language,
			url_
		);

		return `${url}?${LanguageSwitchControllerClass.defaults.country}=${country}&${LanguageSwitchControllerClass.defaults.language}=${language}`;
	}

	/**
	 * @method _getMarketId
	 * @returns {string} returns stock market id
	 * @private
	 */
	_getMarketId() {
		const marketId = scPage.getMarket();
		const marketArray = marketId.split('/');
		return marketArray[0] || '';
	}

	/**
	 * @method _getTarget
	 * @returns {string} returns target from page-template attribute
	 * @private
	 */
	_getTarget() {
		const targetElement = dom.getElement('.nm-area-content');
		return targetElement.getAttribute('data-template') || 'home';
	}

	/**
	 * @method _getFilterString
	 * @returns {string} returns generated filter parameter string
	 * @private
	 */
	_getFilterString() {
		const filterStrings = [...SELECTORS.FILTERS.getAllActiveFiltersMap(STCK_STORE.state).values()].map(filterDTO => filterDTO.toString());
		return filterStrings.join(",") || '';
	}

	/**
	 * @method _getVehicleIdFromUrl
	 * @param {string} url url
	 * @returns {string} returns only the last id (needed for detail page in layer on top of detail page)
	 * @private
	 */
	_getVehicleIdFromUrl(url) {
		const regex = /(sc_detail.*?\.)(([^.\s])+)/gi;
		let vehicleIds = [];
		let matchesArr;

		while ((matchesArr = regex.exec(url))) {
			vehicleIds.push(matchesArr[2]);
		}

		return vehicleIds[vehicleIds.length - 1] || '';
	}

	/**
	 * @method _getPatchParameter
	 * @returns {{marketId: string, target: string, vehicleId: string, filter: string}} returns parameter needed to patch url
	 * @private
	 */
	_getPatchParameter() {
		return {
			marketId: this._getMarketId(),
			target: this._getTarget(),
			vehicleId: this._getVehicleIdFromUrl(location.href),
			filter: this._getFilterString()
		};
	}

	/**
	 * @method _generateGetParameterString
	 * @param {object} parameters patch parameters
	 * @returns {string} returns generated param string
	 * @private
	 */
	_generateGetParameterString(parameters) {
		const marketIdString = !!parameters.marketId
			? `&${LanguageSwitchControllerClass.defaults.market}=${parameters.marketId}`
			: '';
		const filterString = !!parameters.filter
			? `&${LanguageSwitchControllerClass.defaults.filter}=${parameters.filter}`
			: '';
		const targetString = !!parameters.target
			? `&${LanguageSwitchControllerClass.defaults.target}=${parameters.target}`
			: '';
		const vehicleIdString =
			!!parameters.target &&
			parameters.target === 'details' &&
			!!parameters.vehicleId
				? `&${LanguageSwitchControllerClass.defaults.id}=${parameters.vehicleId}`
				: '';

		return `${marketIdString}${filterString}${targetString}${vehicleIdString}`;
	}

	/**
	 * @method _patchUrls
	 * @param {array} languageSwitchUrls languageSwitchUrls
	 * @param {string} getParamString getParamString
	 * @returns {void} returns nothing - patches language switch href s
	 * @private
	 */
	_patchUrls(languageSwitchUrls = [], getParamString = '') {
		languageSwitchUrls.forEach(languageSwitchUrl => {
			const cleanedUrl = this._getCleanedUrl(languageSwitchUrl.href);
			const finishedUrl = `${cleanedUrl}${getParamString}`;
			languageSwitchUrl.href = decodeURIComponent(finishedUrl);
		});
	}

	/**
	 * @method patchLanguageSwitchUrls
	 * @description gets language switch urls from dom and manipulates them with generated get params
	 * @private
	 * @returns {void} returns nothing
	 */
	_patchLanguageSwitchUrls() {
		const languageSwitchUrls = dom.getElementsArray(
			LanguageSwitchControllerClass.defaults.selectorLanguageSwitch
		);
		if (languageSwitchUrls.length) {
			const patchParams = this._getPatchParameter();
			const getParamString = this._generateGetParameterString(
				patchParams
			);
			this._patchUrls(languageSwitchUrls, getParamString);
		}
	}

	/**
	 * @method _addEvents
	 * @returns {void} returns nothing
	 * @private
	 */
	_addEvents() {
		this.globalEventBus.on(
			CoreEvents.PAGE_READY,
			this._patchLanguageSwitchUrls
		);
		this.globalEventBus.on(
			CoreEvents.LAYER_LOADED,
			this._patchLanguageSwitchUrls
		);

		STCK_STORE.observeStateFromStore(SELECTORS.FILTERS.getFilterItemsRaw, this.update);
	}

	/**
	 * @method _bindContextToFunctions
	 * @description bind context to necessary functions
	 * @private
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this.update = this.update.bind(this);
		this._patchLanguageSwitchUrls = this._patchLanguageSwitchUrls.bind(this);
	}

	update() {
		this._patchLanguageSwitchUrls();
	}
}

const languageSwitchController = new LanguageSwitchControllerClass();

export {LanguageSwitchControllerClass, languageSwitchController};
