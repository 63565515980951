export function getPriceFormatted(value, unit='', textUnit='') {
	const pattern = SETUPS.get('stockcar.custom.currency.pattern');
	if (value && pattern) {
		switch (pattern) {
			case '{{currency}}{{price}}':
				return `${unit !== '' ? unit : textUnit} ${value}`;
			case '{{price}}{{currency}}':
				return `${value} ${unit !== '' ? unit : textUnit}`;
			case '{{currency_text}}{{price}}':
				return `${textUnit !== '' ? textUnit : unit} ${value}`;
			case '{{price}}{{currency_text}}':
				return `${value} ${textUnit !== '' ? textUnit : unit}`;
			default:
				const displayUnit = unit !== '' ? unit : (textUnit !== '' ? textUnit : ''); // eslint-disable-line
				return `${value || ''} ${displayUnit}`;
		}
	}
	else {
		return '';
	}
}

const getMatches = (string, pattern) => string.match(new RegExp(pattern.source, pattern.flags)).map(match => new RegExp(pattern.source, pattern.flags).exec(match));

const convertOptions = (acc, current) => {
	const option = current.trim();
	const validOptions = ['bold', 'italic', 'red'];

	return `${acc} ${validOptions.includes(option) ? `sc-audi-warranty-${option}` : 'sc-audi-warranty-default'}`;
};

const getClasses = (stylingOptions = '') => {
	const options = stylingOptions.split(',');
	return options.reduce(convertOptions, '').trim();
};

const getFormattedSnippet = (snippet = []) => {
	const [, , formatOptions, label] = snippet;

	if (!formatOptions && !!label) {
		return label;
	}

	if (!label) {
		return '';
	}

	return `<span class="${getClasses(formatOptions)}">${label}</span>`;
};

const getFormattedLabel = (label = '') => (getMatches(label, /(\[(.*?)])?([^[]+)?/gi) || []).reduce((acc, current) => `${acc}${getFormattedSnippet(current)}`, '');

export const getTextReplace = (id, label = '') => {
	if (!!id && !!window.i18n[`sc.${id}`]) {
		return getFormattedLabel(window.i18n[`sc.${id}`]);
	}
	return label;
};


export const getLocale = () => `${SETUPS.get("nemo.locale.language")}-${SETUPS.get("nemo.locale.country")}`;

/**
 *
 * @param {number} count count
 * @param {number} vagueValue vagueValue
 * @param {boolean} isVagueFilterResults isVagueFilterResults
 * @param {string} locale locale
 * @returns {string} formatted count
 */
export function formatCount(count = 0, vagueValue = 0, isVagueFilterResults = false, locale = getLocale()) {
	if (isVagueFilterResults && count > vagueValue) {
		return (!isNaN(vagueValue * 1) ? new Intl.NumberFormat(locale).format(vagueValue * 1) : count) + '+';
	}
	return !isNaN(count * 1) ? new Intl.NumberFormat(locale).format(count * 1) : count;
}
