import {dom} from 'core-utils';
import {scsController} from '../../stockcars-bundle';
import {scPage} from '../../stockcars-utils-bundle';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';

export default class ScToolbarElement extends HTMLElement {
	constructor() {
		super();
		this.unsubscribeFavoritesObeserver = null;
	}

	static get defaults() {
		return {
			'classNewSearch': '.sc-tb .nm-icon-search-new',
			'classFavoriteToolbarCount': '.sc-tb .nm-icon-favorites .sc-tb-linkcount',
			'classBackLink': '.sc-toolbar-back-link'
		};
	}

	connectedCallback() {
		this.update = this.update.bind(this);
		this.unsubscribeFavoritesObeserver = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getFavoriteVehicleIdsState, this.update);
		this._addEventListeners();
		this.update();
	}

	disconnectedCallback() {
		this._removeEventListeners();
		this.unsubscribeFavoritesObeserver();
	}

	/**
	 * newSearchClickHandler
	 * @param {event} event clickEvent
	 * @return {void} returns nothing
	 */
	async _newSearchClickHandler(event) {
		event.preventDefault();
		const element = dom.closest(event.target, 'a');

		if (element) {
			await scsController.clearAllFilters();
			if (scPage.isDetailsPage(location.href)) {
				const href = element.getAttribute('href');
				scPage.simulateNmPageOpen(href);
			}
		}
	}

	/**
	 * set all favorites
	 * @method _setFavoriteCountInToolbar
	 * @returns {void} returns nothing
	 */
	_setFavoriteCountInToolbar() {
		const toolBarCountElement = dom.getElement(ScToolbarElement.defaults.classFavoriteToolbarCount, this);
		toolBarCountElement.innerHTML = `(${SELECTORS.VEHICLES.getFavoriteVehicleIdsState(STCK_STORE.state).length})`;
	}

	/**
	 * @method setBackLink
	 * @returns {void} returns nothing
	 */
	_setBackLink() {
		const backLink = this._getBackLink();
		if (!!backLink && backLink !== '') {
			const backLinkEl = dom.getElement(ScToolbarElement.defaults.classBackLink);
			backLinkEl.href = backLink;
			backLinkEl.innerText = window.i18n['sc.compare.backlink.back'];
		}
	}

	/**
	 * @method getBackLink
	 * @returns {Object} returns previous page obj
	 */
	_getBackLink() {
		const prevPage = scPage.previousPageObject;
		if (prevPage === null) {
			return '';
		}
		if (!scPage.isDetailsPage(prevPage.hashShort) && !scPage.isDetailsPage(window.location.hash)) {
			this._previousPage = !!prevPage.hashShort ? prevPage.hashShort : prevPage.pathName;
		}
		return this._previousPage || '';
	}

	/**
	 * add all Event Listener
	 *
	 * @method addEventListener
	 * @return {void} returns nothing
	 */
	_addEventListeners() {
		this._newSearchClickHandler = this._newSearchClickHandler.bind(this);
		this._newSearchButton = this.querySelector(ScToolbarElement.defaults.classNewSearch);
		this._newSearchButton.addEventListener('click', this._newSearchClickHandler);
	}

	/**
	 * remove all Event Listener
	 *
	 * @method addEventListener
	 * @return {void} returns nothing
	 */
	_removeEventListeners() {
		this._newSearchButton.removeEventListener('click', this._newSearchClickHandler);
	}

	update() {
		this._setFavoriteCountInToolbar();
	}
}

if (window.customElements.get('sc-toolbar-element') === undefined) {
	window.customElements.define('sc-toolbar-element', ScToolbarElement);
}
