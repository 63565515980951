// eslint-disable no-useless-escape
const template = `<div class="nm-su-result">
	{{?!!it.audicode}}
		{{?!!it.configuration}}
		<div class="nm-su-result-wrap">
			<div class="nm-su-result-audicode clearfix">
				{{? SETUPS.get('scope.nemo.audicode.externallink')}}
					{{var target = "target='blank'";}}
					{{var link = '/' + it.audicode;}}
				{{??}}
					{{var target = "";}}
					{{var link = "#";}}
				{{?}}
				{{?it.success===false}}
					{{?it['vtp-audicode'] && it['other-url']}}
					<div class="nm-su-vtp">
						<a href="{{=it['other-url']}}" class="nm-link-cta audi-j-quick-search-result-tracking" {{=target}}>
							<span class="nm-su-result-audicode-label">{{=window.i18n['search-layer.your-audicode']}}</span>&nbsp;
							 <strong>{{=it.audicode}}</strong>
							<svg class="nm-icon-arrow">
								<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
							</svg>
						</a>
						<div class="nm-su-vtp-image"></div>
						<div class="nm-su-vtp-description">
							<span class="nm-headline-teaser">
								{{=window.i18n['search-layer.available-cars']}}
							</span>
							<div class="nm-description nm-label-info">
								{{=window.i18n['search-layer.available-cars-info']}}
							</div>
							<a href="{{=it['other-url']}}" class="audi-j-quick-search-result-tracking nm-el-btn nm-el-btn-01 nm-el-btn-ast" {{=target}}>
								<span>{{=window.i18n['search-layer.your-car']}}</span>
							</a>
							<div class="nm-text-list">
								<span class="nm-text-list-bold">
									{{=window.i18n['search-layer.label.disclaimer']}}:
								</span>
								{{=window.i18n['search-layer.vtp-warning']}}
							</div>
						</div>
					</div>
					{{??}}
						<strong class="nm-headline-teaser">{{=it.message}}</strong>
					{{?}}
				{{??}}
					<a href="{{=link}}" class="nm-link-cta nm-j-audicode-accept audi-j-quick-search-result-tracking" {{=target}}>
						<span class="nm-su-result-audicode-label">{{=window.i18n['search-layer.your-audicode']}}</span>&nbsp;
						<strong>{{=it.audicode}}</strong>
						<svg class="nm-icon-arrow">
							<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
						</svg>
					</a>
					<img class="nm-su-result-audicode-image" src="{{=it.mediaservice}}{{=it.configuration.configuration.assets['exterior_front']['compare.new']}}"/>
					<div class="nm-su-result-audicode-description">
						<span class="nm-headline-teaser">
							{{=it.configuration.configuration.carlineName}}
						</span>
						<div class="nm-description nm-label-info">
							{{=it.configuration.configuration.description}}
						</div>
						<strong class="nm-headline-teaser nm-su-result-audicode-price">
							{{=it.configuration.configuration.price}}
						</strong>
						<a href="{{=link}}" {{=target}} class="nm-j-audicode-accept audi-j-quick-search-result-tracking nm-el-btn nm-el-btn-01 nm-el-btn-ast">{{=window.i18n['search-layer.load-configuration']}}</a>
						<div class="nm-text-list">
							<span class="nm-text-list-bold">
								{{=window.i18n['search-layer.label.disclaimer']}}:
							</span>
							{{=window.i18n['search-layer.configuration-overwrite-warning']}}
						</div>
						{{? it.configuration.configuration.eec && (it.configuration.configuration.eec.consumption || it.configuration.configuration.eec.co2emission || it.configuration.configuration.eec.efficiency)}}
							<dl class="nm-su-result-audicode-consumption-entries clearfix audi-copy-s">
								{{? it.configuration.configuration.eec.consumption}}
									<dt class="nm-su-result-audicode-consumption-label">{{=window.i18n['nemo.ui.consumption-combined']}}*:</dt>
									<dd class="nm-su-result-audicode-consumption-value">{{=it.configuration.configuration.eec.consumption}}</dd>
								{{?}}
								{{? it.configuration.configuration.eec.co2emission}}
									<dt class="nm-su-result-audicode-consumption-label">{{=window.i18n['nemo.ui.emission-combined']}}*:</dt>
									<dd class="nm-su-result-audicode-consumption-value">{{=it.configuration.configuration.eec.co2emission}}</dd>
								{{?}}
								{{? it.configuration.configuration.eec.efficiency}}
									<dt class="nm-su-result-audicode-consumption-label">{{=window.i18n['nemo.ui.efficiency-class']}}:</dt>
									<dd class="nm-su-result-audicode-consumption-value">{{=it.configuration.configuration.eec.efficiency}}</dd>
								{{?}}
							</dl>
						{{?}}
					</div>
				{{?}}
			</div>
		</div>
		{{??}}
			<div class="nm-su-result-audicode">
				<strong class="nm-headline-teaser">
					{{=window.i18n['search-layer.audicode-loading']}}
				</strong>
				<div class="nm-j-audicode-loading nm-loading">
				</div>
			</div>
		{{?}}
	{{?}}
	{{?!it.audicode}}
		<div class="nm-su-result-wrap">
			{{?it.search.length}}
				<ul class="nm-su-result-list-group">
					{{~ it.search :entry }}
						<li>
							<h2 class="nm-su-result-list-item-headline">
								<span class="nm-label-text-bold">{{=entry.value.carlineName}}
									<svg class="nm-icon-arrow">
										<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
									</svg>
								</span>
								<span class="nm-label-text-bold">{{=entry.key}}</span>
							</h2>
							<ul class="nm-su-result-list">
								{{~ entry.value.items :item }}
									<li class="nm-j-configurator-item nm-j-configurator-status_{{=item.status}} clearfix"
									data-configurator-id="{{=item.id}}">
										<div class="nm-su-result-image">
											{{? !!item.image}}
												<img src="{{=item.image}}" alt="{{=item.name}}" class="nm-image" />
											{{?}}
										</div>
										<div class="nm-su-result-list-content">
											<div class="nm-j-configurator-name nm-text-list-bold">
												{{=item.name}}
												{{? !!item.nonrenderable && item.nonrenderable == true }}
													<span class="nm-text-list"><br />{{=window.i18n['configuration.not-rendable-message']}}</span>
												{{?}}
											</div>
											{{? !!item.description}}
											<div class="nm-su-result-list-description nm-text-list">
												{{= item.description || ''}}
											</div>
											{{?}}
										</div>
										<div class="nm-su-result-list-info">
											<div class="nm-su-result-list-add clearfix">
												{{?!!item.url}}
													<a class="nm-info nm-layerLink" href="{{=item.url}}" title="{{=window.i18n['more-information']}}"></a>
												{{?}}
												<span class="nm-conflict-icon" title="#I18N("nemo.ui.common.conflict" "Konflikt")"></span>
												<div class="nm-su-result-list-price nm-text-list">
													{{? !!item.price}}
														<span class="nm-j-configurator-text-price">{{=item.price}}</span>
													{{?}}
												</div>
											</div>
											<div class="nm-su-result-list-add-btn">
												<a class="nm-el-btn nm-el-btn-02 nm-j-configurator-delegate-click" href="#">
													<span class="nm-at-btn-state01">{{=window.i18n['search-layer.add-item']}}</span>
													<span class="nm-at-btn-state02">{{=window.i18n['search-layer.item-included']}}</span>
													<span class="nm-at-btn-state03">{{=window.i18n['search-layer.remove-item']}}</span>
												</a>
											</div>
										</div>
									</li>
								{{~}}
							</ul>
						</li>
					{{~}}
				</ul>
			{{?}}
			<div class="nm-su-sitesearch-list">
				{{?it.modelSearch.length}}
					<ul class="nm-su-modelsearch-group">
						{{~it.modelSearch :modelItem}}
						<li>
							<a class="nm-link-basic audi-j-quick-search-result-tracking" href="{{=modelItem.url}}">
								<span>{{=modelItem.name}}</span>
								<svg class="nm-icon-arrow">
									<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
								</svg>
							</a>
						</li>
						{{~}}
					</ul>
				{{?}}
				{{?it.siteMapSearch.length}}
					<ul class="nm-su-sitemapsearch-group">
						{{~it.siteMapSearch :siteMapItem}}
							{{var itemName = siteMapItem.name;
							var rhombus = /\[rhombus\]/ig;
							var plus = /\:plus/ig;
							var replacedName = itemName.replace( rhombus, '***');/*<span class="nm-icon-rhombus"></span>' );*/
							replacedName = replacedName.replace( plus, '<span class="nm-brand-plus">:plus</span>' );
						}}
						<li>
							<a class="nm-link-basic audi-j-quick-search-result-tracking" href="{{=siteMapItem.url}}">
								<span>{{=replacedName}}</span>
								<svg class="nm-icon-arrow">
									<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
								</svg>
							</a>
						</li>
						{{~}}
					</ul>
				{{?}}
				{{?it.footerSearch.length}}
					<ul class="nm-su-footersearch-group">
						{{~it.footerSearch :footerItem}}
							<li>
								<a class="nm-link-basic audi-j-quick-search-result-tracking" href="{{=footerItem.url}}">
								<span>{{=footerItem.name}}</span>
								<svg class="nm-icon-arrow">
									<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
								</svg>
							</a>
							</li>
						{{~}}
					</ul>
				{{?}}
			</div>
			/*Proposals*/
			<ul class="nm-su-result-list-group">
				{{?it.searchListProposals.length}}
					<li>
						<ul class="nm-su-result-list nm-su-proposal-list">
							{{~it.searchListProposals :proposalItem}}
								<li>
									<a href="{{=proposalItem.Url}}" x-cq-linkchecker="valid" class="audi-j-quick-search-result-tracking nm-su-proposal-item{{?proposalItem.Type=="Layer"}} nm-layerLink{{?}}">
										<div class="nm-su-result-image">
											{{? !!proposalItem.ImageUrl}}
												<img src="{{=proposalItem.ImageUrl}}" alt="{{=proposalItem.Headline}}" class="nm-image" />
											{{?}}
										</div>
										<div class="nm-su-proposal-list-content">
											<strong class="nm-text-list-bold">{{=proposalItem.Headline}}</strong>
											<div class="nm-su-result-list-description nm-text-list">{{=proposalItem.Description}}</div>
											<span class="nm-link-basic audi-j-quick-search-result-tracking" >
												<span>{{=window.i18n['discover-now']}}</span>
												<svg class="nm-icon-arrow">
													<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
												</svg>
											</span>
										</div>
									</a>
								</li>
							{{~}}
						</ul>
					</li>
				{{?}}
			</ul>
		</div>
		{{?!!it.gsaSearchUrl}}
			<div class="nm-su-gsa">
				<a class="nm-j-tracking-search-submit nm-link-basic" href="{{=it.gsaSearchUrl}}">
					<span>{{=window.i18n['search-layer.search-audi-web']}} &quot;<span class="nm-highlight">{{=it.searchString}}</span>&quot; {{=window.i18n['search-layer.search-for']}}</span>
					<svg class="nm-icon-arrow">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/arrow.svg#nm-icon-arrow"}}></use>
					</svg>
				</a>
			</div>
		{{?}}
	{{?}}

</div>`;
export {template as searchLayerTemplate};
